import { setLoading } from "redux/actions/app.actions";
import { cachedRequest, request } from "./verb.services";
import { openNotificationWithIcon } from "utils/Notification";
import {
  setAllGuests,
  addGuest,
  setUserNotes,
  updateGuest as updateGuestAction,
} from "redux/actions/user.actions";
import { PAYMENT_METHOD_TYPE } from "utils/enums";
import { sortBy } from "lodash";

export const getCachedGuests = (
  skip = 0,
  pageSize = 10,
  sortBy = "FirstName",
  asc = true,
  searchString = "",
  active,
  signal,
  showLoading = true
) => {
  console.log("active", active);
  return (dispatch) => {
    if (showLoading) dispatch(setLoading(true));
    return cachedRequest(
      `Guests?pageSize=${pageSize}&skip=${skip}&sortBy=${sortBy}&asc=${asc}&searchString=${searchString}${
        active !== null ? `&includeActive=${active}` : ""
      }`,
      "get",
      null,
      true,
      null,
      {
        signal,
      }
    )
      .then(({ data }) => {
        data?.items?.forEach(
          (guest) => (guest.name = guest.firstName + " " + guest.lastName)
        );
        dispatch(setLoading(false));
        return {
          status: "success",
          data: data?.items,
          totalRecords: data?.totalRecords,
        };
      })
      .catch((e) => {
        if (e?.name === "AbortError" || e?.message === "canceled") return;
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.message || e?.message || "Something went wrong"
        );
        if (showLoading) dispatch(setLoading(false));
      })
      .finally(() => {});
  };
};

export const getGuests = (
  skip = 0,
  pageSize = 10,
  sortBy = "FirstName",
  asc = true,
  searchString = "",
  active,
  signal,
  showLoading = true
) => {
  console.log("active", active);
  return (dispatch) => {
    if (showLoading) dispatch(setLoading(true));
    return request(
      `Guests?pageSize=${pageSize}&skip=${skip}&sortBy=${sortBy}&asc=${asc}&searchString=${searchString}${
        active !== null ? `&includeActive=${active}` : ""
      }`,
      "get",
      null,
      true,
      null,
      {
        signal,
      }
    )
      .then(({ data }) => {
        data?.items?.forEach(
          (guest) => (guest.name = guest.firstName + " " + guest.lastName)
        );
        dispatch(setLoading(false));
        return {
          status: "success",
          data: data?.items,
          totalRecords: data?.totalRecords,
        };
      })
      .catch((e) => {
        if (e?.name === "AbortError" || e?.message === "canceled") return;
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.message || e?.message || "Something went wrong"
        );
        if (showLoading) dispatch(setLoading(false));
      })
      .finally(() => {});
  };
};

export const getAllGuests = () => {
  return (dispatch) => {
    return request(
      "Guests?pageSize=999999999&includeActive=true",
      "get",
      null,
      true
    )
      .then(({ data }) => {
        data?.items?.forEach(
          (guest) => (guest.name = guest.firstName + " " + guest.lastName)
        );
        const sortedData = data?.items?.sort((a, b) => {
          let aName =
            a.firstName?.toLowerCase?.() + " " + a.lastName?.toLowerCase?.();
          let bName =
            b.firstName?.toLowerCase?.() + " " + b.lastName?.toLowerCase?.();
          if (aName < bName) return -1;
          if (aName > bName) return 1;
          return 0;
        });

        dispatch(setAllGuests(sortedData));
        return sortedData;
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.message || e?.message || "Something went wrong"
        );
      });
  };
};

/**
 * @param {number} customerId  - id of the customer
 * */
export const getCustomerPaymentMethods = (customerId, callback) => {
  return (dispatch) => {
    return request(
      `PaymentMethods?customerId=${customerId}&type=${PAYMENT_METHOD_TYPE.Stripe}`,
      "get",
      null,
      true,
      null
    )
      .then((result) => {
        callback?.(result?.data);
        return {
          status: "success",
          data: result.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.message || e?.message || "Something went wrong"
        );
      })
      .finally(() => {});
  };
};

export const getCustomerSetupIntent = (customerId) => {
  return (dispatch) => {
    // Get the Secret to setup Add Payment Method
    return request(
      `PaymentMethods/SetupIntent?customerId=${customerId}`,
      "get",
      null,
      true
    )
      .then((response) => {
        return {
          data: response.data,
          status: "success",
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ?? e.message ?? "Network error has occured"
        );
      });
  };
};

export const postPaymentMethodId = (paymentMethodId, customerId) => {
  return (dispatch) => {
    // Get the Secret to charge Payment Method
    return request(
      `PaymentMethods?customerId=${customerId}`,
      "post",
      { paymentMethodId },
      true
    )
      .then((response) => {
        return {
          status: "success",
          response: response.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ?? e.message ?? "Network error has occured"
        );
      });
  };
};

export const setPrimaryPaymentMethod = (
  newPrimaryPaymentMethodId,
  customerId,
  oldPrimaryPaymentMethodId
) => {
  return (dispatch) => {
    // Get the Secret to charge Payment Method
    return request(
      `PaymentMethods/${newPrimaryPaymentMethodId}?customerId=${customerId}&${
        oldPrimaryPaymentMethodId
          ? `&oldPrimaryPaymentMethodId=${oldPrimaryPaymentMethodId}`
          : ""
      }`,
      "patch",
      null,
      true
    )
      .then((response) => {
        return {
          status: "success",
          response: response.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ?? e.message ?? "Network error has occured"
        );
      });
  };
};

export const createGuest = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request("Guests", "post", data, true)
      .then((response) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Guest created successfully"
        );
        if (response.data) {
          response.data.fullName =
            response.data.firstName + " " + response.data.lastName;
          response.data.name =
            response.data.firstName + " " + response.data.lastName;
          dispatch(addGuest(response.data));
        }
        return {
          status: "success",
          data: response.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error",
          e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

/**
 * @param {number} customerId  - id of the customer
 * */
export const getCustomerDetails = (customerId, callback) => {
  return (dispatch) => {
    return request(`Guests/${customerId}`, "get", null, true, null)
      .then((result) => {
        callback?.(result?.data);
        return {
          status: "success",
          data: result.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.message || e?.message || "Something went wrong"
        );
      })
      .finally(() => {});
  };
};
export const getCustomerFiles = (customerId, callback) => {
  return (dispatch) => {
    return request(`Documents/`, "get", null, true, { guestId: customerId })
      .then((result) => {
        callback?.(result?.data);
        return {
          status: "success",
          data: result.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.message || e?.message || "Something went wrong"
        );
      })
      .finally(() => {});
  };
};

export const deleteCustomerFile = (documentId, callback) => {
  console.log("detlete", documentId);
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Documents/${documentId}`, "delete", null, true, null)
      .then((result) => {
        return {
          status: "success",
          data: result.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.message || e?.message || "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const uploadCustomerFile = (data, callback) => {
  return request(`Documents/`, "post", data, true, null)
    .then((result) => {
      callback?.(result?.data);
      return {
        status: "success",
        data: result.data,
      };
    })
    .catch((e) => {
      openNotificationWithIcon(
        "error",
        "Error!",
        e?.response?.message || e?.message || "Something went wrong"
      );
    })
    .finally(() => {});
};

export const updateCustomerNotes = (data, guestId, noteId, callback) => {
  return (dispatch) => {
    return request(`Guests/${guestId}/Notes/${noteId}`, "put", data, true, null)
      .then((result) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Note updated successfully"
        );
        callback?.(result?.data);
        // dispatch(setUserNotes({ ...data, customerId: guestId, noteId }));
        return {
          status: "success",
          data: result.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.message || e?.message || "Something went wrong"
        );
      })
      .finally(() => {});
  };
};

export const createCustomerNotes = (data, guestId, callback) => {
  return (dispatch) => {
    return request(`Guests/${guestId}/Notes`, "post", data, true, null)
      .then((result) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Note created successfully"
        );
        callback?.(result?.data);
        // dispatch(setUserNotes(data));
        return {
          status: "success",
          data: result.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.message || e?.message || "Something went wrong"
        );
      })
      .finally(() => {});
  };
};

export const getCustomerNotes = (guestId, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Guests/${guestId}/Notes`, "get", null, true, null)
      .then(({ data }) => {
        // moved notes from redux to local state
        // dispatch(setUserNotes(data));
        callback?.(data);
        return { data, status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.message || e?.message || "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const clearCustomerNotes = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(setUserNotes([]));
      resolve();
    });
  };
};

export const updateGuest = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Guests`, "put", data, true)
      .then((result) => {
        dispatch(updateGuestAction(result?.data));
        openNotificationWithIcon(
          "success",
          "Success",
          "Guest updated successfully"
        );
        return {
          status: "success",
          data: result.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.message || e?.message || "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const sendWelcomeEmail = (guestId) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Guests/${guestId}/WelcomeEmail`, "patch", null, true)
      .then((response) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Welcome email sent successfully"
        );
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.[0] ||
            e?.message ||
            "Network error has occured"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const deleteNote = (guestId, noteId, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));

    return request(
      `Guests/${guestId}/Notes/${noteId}`,
      "delete",
      null,
      true,
      null
    )
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Note deleted successfully"
        );
        callback?.();
        return { status: "success" };
      })

      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getRewards = (customerId) => {
  return (dispatch) => {
    //   dispatch(setSubmitting(true));
    return request(`Guests/${customerId}/RewardGroups`, "get", null, true)
      .then((response) => {
        const data = response.data;
        return { status: "success", data };
      })
      .catch((e, f) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.message ||
            e?.response?.data?.[0]?.description ||
            "Error while fetching rewards"
        );
      });
    // .finally(() => dispatch(setSubmitting(false)));
  };
};

export const getProgramsAndMemberships = (
  customerId,
  includeOnlyPurchased = true
) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `Guests/${customerId}/Programs?includeOnlyPurchased=${includeOnlyPurchased}`,
      "get",
      null,
      true
    )
      .then((response) => {
        const data = response.data;
        return { status: "success", data };
      })
      .catch((e, f) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.message ||
            e?.response?.data?.[0]?.description ||
            "Error while Programs and Memberships"
        );
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const getCustomerWalletCredits = (customerId) => {
  return (dispatch) => {
    return request(
      `Guests/AvailableCredits?guestId=${customerId}`,
      "get",
      null,
      true
    )
      .then((result) => {
        return {
          status: "success",
          data: result.data ?? 0,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.message || e?.message || "Something went wrong"
        );
      })
      .finally(() => {});
  };
};

export const purchaseProgram = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request("orders/program", "post", data, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success!",
          "Program purchased successfully. Please wait 10 secs for programs and memberships to be updated."
        );
        return {
          status: "success",
          data: res.data,
        };
      })
      .catch((e) => {
        console.log("e", e);

        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.Message ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.response?.Message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};
export const cancelMembership = (customerProgramId, customerId) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `Orders/Programs/Cancel?customerProgramId=${customerProgramId}&customerId=${customerId}`,
      "patch",
      null,
      true
    )
      .then((response) => {
        openNotificationWithIcon(
          "success",
          "Success!",
          "Membership cancelled. Please wait 10 secs for programs and memberships to be updated."
        );
        return {
          response: response.data,
          status: "success",
        };
      })
      .catch((e) => {
        console.log("e", e);
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.response?.Message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const inactivateGuest = (id, prevSource) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Guests/${id}/Status`, "patch", null, true)
      .then(() => {
        // let newSource = [...prevSource];
        // newSource = newSource.filter((o) => o.staffId !== id);
        openNotificationWithIcon(
          "success",
          "Success",
          "Guest inactivated successfully"
        );
        // dispatch(setWholeStaff(newSource));
        return { status: "success" };
      })
      .catch((e) => {
        console.log(e);
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const activateGuest = (id, prevSource) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Guests/${id}/Status`, "patch", null, true)
      .then(() => {
        // let newSource = [...prevSource];
        // newSource = newSource.filter((o) => o.staffId !== id);
        openNotificationWithIcon(
          "success",
          "Success",
          "Guest activated successfully"
        );
        // dispatch(setWholeStaff(newSource));
        return { status: "success" };
      })
      .catch((e) => {
        console.log(e);
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getIntakeFormNotes = (customerId, formValueId, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `Guests/${customerId}/FormValues/${formValueId}/Notes`,
      "get",
      null,
      true,
      null
    )
      .then(({ data }) => {
        // moved notes from redux to local state
        // dispatch(setUserNotes(data));
        callback?.(data);
        return { data, status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.message || e?.message || "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const createIntakeFormNote = (
  customerId,
  formVersionId,
  data,
  callback
) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `Guests/${customerId}/FormValues/${formVersionId}/Notes`,
      "post",
      data,
      true
    )
      .then((response) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Note created successfully"
        );
        if (response.data) {
          callback?.(response.data);
        }
        return {
          status: "success",
          data: response.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error",
          e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getGoodFaiths = (
  skip = 0,
  pageSize = 10,
  searchString = "",
  sorting,
  showLoading = true,
  signal
) => {
  console.log("searchString", skip, pageSize, searchString, showLoading);
  return (dispatch) => {
    if (showLoading) dispatch(setLoading(true));
    const searchStringQuery = searchString
      ? `&searchString=${searchString}`
      : "";

    const sortingQuery = sorting ? `&sortBy=${sorting}` : "";
    return request(
      `Forms/FilterValues?pageSize=${pageSize}&skip=${skip}&includeActive=true${sortingQuery}${searchStringQuery}`,
      "get",
      null,
      true,
      null,
      { signal }
    )
      .then(({ data }) => {
        dispatch(setLoading(false));
        return {
          status: "success",
          data: data?.items,
          totalRecords: data?.totalRecords,
        };
      })
      .catch((e) => {
        if (e?.name === "AbortError" || e?.message === "canceled") return;
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.message || e?.message || "Something went wrong"
        );
        if (showLoading) dispatch(setLoading(false));
      })
      .finally(() => {});
  };
};

export const generateProfilePDF = (guestId, emails, showLoading) => {
  return (dispatch) => {
    if (showLoading) dispatch(setLoading(true));
    return request(`Guests/${guestId}/ProfilePdf`, "post", emails, true, null)
      .then(({ data }) => {
        dispatch(setLoading(false));
        openNotificationWithIcon(
          "success",
          "Success",
          "PDF generated successfully"
        );
        return {
          status: "success",
          data,
        };
      })
      .catch((e) => {
        if (e?.name === "AbortError" || e?.message === "canceled") return;
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.message || e?.message || "Something went wrong"
        );
        if (showLoading) dispatch(setLoading(false));
      })
      .finally(() => {});
  };
};
