import React from "react";
import { DatePicker, Grid, Segmented, Select, Space, Input } from "antd";

import { ReactComponent as ChevronLeft } from "assets/icons/chevronLeftIco.svg";
import { ReactComponent as ChevronRight } from "assets/icons/chevronRightIco.svg";
import moment from "moment";
import { getChartsByStatus } from "services/charts.services";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "redux/actions/app.actions";
import Charts from "./Charts.index";
import GoodFaithsList from "./GoodFaithsList";
import SegmentButton from "./components/SegmentButton";
import debounce from "lodash/debounce";

const { useBreakpoint } = Grid;

const DEFAULT_PAGE = 1;

export default function ChartsAndGFEs() {
  const screens = useBreakpoint();
  const dispatch = useDispatch();

  const isSmallDevice = !screens.md;

  const [page, setPage] = React.useState(DEFAULT_PAGE);
  const [open, setOpen] = React.useState(false);
  const [date, setDate] = React.useState(moment());
  const [sorting, setSorting] = React.useState("all");
  const [gfeSorting, setGfeSorting] = React.useState("All");
  const currentStaff = useSelector((state) => state.user.currentUser);
  const allRoles = useSelector((state) => state.roles.allRoles);
  const hasGFEPermission = React.useMemo(() => {
    const role = allRoles?.find?.(
      (role) => role.roleId === currentStaff?.roleId
    );
    if (
      role?.permissions?.find?.(
        (permission) => permission.permissionName === "GFE Signature"
      )
    ) {
      return true;
    }
    return false;
  }, [allRoles, currentStaff]);

  const [searchText, setSearchText] = React.useState("");

  const [tab, setTab] = React.useState("Charts");

  const debouncedChange = debounce((value) => {
    setPage(1);
    setSearchText(value);
  }, 1000);

  const onNavigate = async (value) => {
    if (value === "add") {
      const newDate = moment(date).add(1, "day");
      setDate(newDate);
    } else {
      const newDate = moment(date).subtract(1, "day");
      setDate(newDate);
    }
  };

  return (
    <div>
      <div
        style={{
          height: isSmallDevice ? "auto" : 80,
          background: "#e5e8ee",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: isSmallDevice ? "column" : "row",
          paddingBottom: isSmallDevice ? 16 : 0,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 12,
            marginBottom: isSmallDevice ? 16 : 0,
            flexWrap: "wrap",
          }}
        >
          {/* <Segmented
            options={["Charts", "GFE"]}
            size="large"
            onChange={(value) => setTab(value)}
          /> */}
          <SegmentButton
            options={hasGFEPermission ? ["Charts", "GFE"] : ["Charts"]}
            selected={tab}
            onChange={(value) => setTab(value)}
          />

          {tab === "Charts" && (
            <div className="dayWeekSelectorContainer">
              <button
                className="arrows"
                onClick={() => {
                  onNavigate("less");
                }}
              >
                <ChevronLeft />
              </button>
              <div style={{ position: "relative" }}>
                <DatePicker
                  dropdownClassName="dayWeekSelectorDropdown"
                  defaultValue={date}
                  value={date}
                  placement="bottomLeft"
                  bordered={false}
                  open={open}
                  className="hiddenDatePicker"
                  style={{ visibility: "hidden", width: 0 }}
                  onOpenChange={(open) => setOpen(open)}
                  dropdownAlign={{
                    offset: [-68, 14],
                  }}
                  onChange={async (date) => {
                    setDate(date);
                    dispatch(setLoading(true));
                    await dispatch(
                      getChartsByStatus("Pending", {
                        serviceDate: date.format(),
                        staffId:
                          currentStaff && currentStaff.roleName !== "Admin"
                            ? currentStaff.staffId
                            : null,
                      })
                    );
                    dispatch(setLoading(false));
                  }}
                />
                <button className="dateButton" onClick={() => setOpen(!open)}>
                  {date.format("DD MMM YYYY")}
                </button>
              </div>
              <button
                className="arrows"
                onClick={() => {
                  onNavigate("add");
                }}
              >
                <ChevronRight />
              </button>
            </div>
          )}
          {tab === "GFE" && (
            <Input.Search
              defaultValue={searchText}
              onChange={(e) => {
                if (e.target.value === "") {
                  setSearchText("");
                  return;
                }
                debouncedChange.cancel();
                // debounce(() => setSearchText(e.target.value), 250);
                debouncedChange(e.target.value);
              }}
              placeholder={"Search"}
              // onSearch={onSearch}
              allowClear
              style={{ width: 200 }}
            />
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: isSmallDevice ? "space-between" : "center",
            alignItems: "center",
          }}
        >
          <Space className="locationSelectorContainer">
            <span className="label">Sort By: </span>
            {tab === "Charts" ? (
              <Select
                className="locationSelector"
                dropdownClassName="locationSelectorDropdown"
                // showSearch
                placeholder="Select"
                optionFilterProp="children"
                value={sorting}
                onChange={(val) => {
                  setSorting(val);
                }}
                // loading={loading}
                options={[
                  { label: "All", value: "all" },
                  { label: "Pending", value: "pending" },
                  { label: "Signed off", value: "signedOff" },
                  { label: "Newest to oldest", value: "newestToOldest" },
                  { label: "Oldest to newest", value: "oldestToNewest" },
                  { label: "Clinic", value: "inClinic" },
                  { label: "Mobile", value: "mobile" },
                ]}
              />
            ) : (
              <Select
                className="locationSelector"
                dropdownClassName="locationSelectorDropdown"
                // showSearch
                placeholder="Select"
                optionFilterProp="children"
                // value={facility}
                onChange={(val) => {
                  setGfeSorting(val);
                }}
                value={gfeSorting}
                options={[
                  { label: "All", value: "All" },
                  { label: "Latest", value: "ModifiedOn" },
                  { label: "Signed off", value: "SignOffCount" },
                ]}
              />
            )}
          </Space>
        </div>
      </div>
      {tab === "Charts" ? (
        <Charts date={date} sorting={sorting} />
      ) : (
        <GoodFaithsList
          page={page}
          setPage={setPage}
          isSmallDevice={isSmallDevice}
          searchText={searchText || ""}
          sorting={gfeSorting === "All" ? undefined : gfeSorting}
        />
      )}
    </div>
  );
}
