import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Modal from "components/Modal/Modal";
import { Button } from "components/Buttons/Buttons";

import {
  Form,
  Typography,
  Checkbox,
  Skeleton,
  Button as AntdButton,
  Modal as AntdModal,
  Select,
} from "antd";

import Signature, { useSignature } from "components/Signature/Signature";
import { openNotificationWithIcon } from "utils/Notification";
import { getForm, getFormByType } from "services/forms.service";
import FormParsedUI from "containers/FormsManagement/components/FormParseUi";
import moment from "moment";
import {
  postForm,
  postGoodFaithForm,
  updateGoodFaithPhysician,
} from "services/intakeForm.service";
import { FormType } from "utils/enums";
import {
  FormButton,
  FormHiddenSubmitButton,
} from "components/FormItems/FlatFormItems";
import Notes from "./Notes";
import styles from "../../Charts/Charts.module.scss";

const { Title, Text } = Typography;

const DATE_INDICATOR = "{{DATE}}";

export default function IntakeFormModal({
  visible,
  title = "",
  type,
  onSubmit,
  onClose,
  initialValues = {},
  formId,
  isSubmitted = false,
  isEdit = false,
  customerId,
  formCode,
  orderId,
  className,
  formValueId,
  refreshData,
}) {
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const submitBtnRef = useRef();

  const [record, setRecord] = useState();
  const dispatch = useDispatch();

  const wholeStaff = useSelector((state) => state.user?.wholeStaff);
  const allRoles = useSelector((state) => state.roles.allRoles);
  const filteredStaff = React.useMemo(() => {
    return wholeStaff
      ?.filter?.((staff) => {
        const role = allRoles?.find?.((role) => role.roleId === staff?.roleId);
        if (
          role?.permissions?.find?.(
            (permission) => permission.permissionName === "GFE Signature"
          )
        )
          return staff.isActive;
        return false;
      })
      ?.map((staff) => ({
        value: staff.staffId,
        label: staff.firstName + " " + staff.lastName,
      }));
  }, [wholeStaff, allRoles]);

  const notesRef = useRef([]);

  const [signature, getSignatureValue, clearSignatureValue, setSignatureValue] =
    useSignature(className, form);
  const [
    signature1,
    getSignatureValue1,
    clearSignatureValue1,
    setSignatureValue1,
  ] = useSignature(className, form, "signature1");

  // const [selectSecondPhysicianValue, setSelectSecondPhysicianValue] =
  //   useState(null);

  const partialEdit = isEdit == true && isSubmitted == false && !!formId;
  const { firstPhysicianStaffId, secondPhysicianStaffId } = record ?? {};
  const currentUser = useSelector((state) => state.user?.currentUser);
  const selectedSecondNp = Form.useWatch("selectSecondPhysicianValue", form2);

  let isNotesDisabled = true;
  const isFirstPhysicianLoggedIn =
    currentUser?.staffId === firstPhysicianStaffId;
  const isSecondPhysicianLoggedIn = currentUser?.staffId === selectedSecondNp;

  const [isNP1GoodFaithSignedOff, isNP2GoodFaithSignedOff] =
    React.useMemo(() => {
      let isNP1Signed = false;
      let isNP2Signed = false;
      if (record?.values) {
        isNP1Signed = !!record?.values?.np1Signature;
        isNP2Signed = !!record?.values?.np2Signature;
      }
      return [isNP1Signed, isNP2Signed];
    }, [record, currentUser]);

  const shoudDisableGFE = React.useMemo(() => {
    if (title === "Good Faith Exam") {
      if (
        isNP1GoodFaithSignedOff &&
        selectedSecondNp !== currentUser?.staffId
      ) {
        return true;
      } else if (isNP2GoodFaithSignedOff) {
        return;
      }
    }
    return false;
  }, [
    firstPhysicianStaffId,
    secondPhysicianStaffId,
    currentUser,
    record,
    title,
    record,
    isNP1GoodFaithSignedOff,
    isNP1GoodFaithSignedOff,
    selectedSecondNp,
  ]);

  if (currentUser?.roleName === "Admin") {
    isNotesDisabled = false;
  } else if (
    currentUser?.staffId === firstPhysicianStaffId ||
    currentUser?.staffId === secondPhysicianStaffId
  ) {
    isNotesDisabled = false;
  }
  const [agreeAllConsensts, setAgreeAllConsents] = useState(
    isEdit ? false : true
  );
  const [requiredSecondSignature, setRequiredSecondSignature] = useState(false);

  const [error, setError] = useState(false);

  const loadData = (renderDesign = true) => {
    // get data
    if (!isEdit || partialEdit) {
      dispatch(
        getForm({
          formValueId: formId,
        })
      ).then((res) => {
        const data = res?.data;
        const {
          designJSON,
          valueJSON,
          formVersionId,
          formValueId,
          formType,
          formId,
          firstPhysicianStaffId,
          secondPhysicianStaffId,
          requiredSecondSignature: incomingRequiredSecondSignature,
        } = data ?? {};
        let design = {};
        let values = {};
        try {
          design = JSON.parse(designJSON || "{}");
          values = JSON.parse(valueJSON || "{}");
        } catch (e) {
          console.log("parse error");
        }

        setRecord({
          design,
          formVersionId,
          formValueId,
          formType,
          formId,
          values,
          firstPhysicianStaffId,
          secondPhysicianStaffId,
          requiredSecondSignature: incomingRequiredSecondSignature,
        });

        setRequiredSecondSignature(!!incomingRequiredSecondSignature);
        // setSelectSecondPhysicianValue(secondPhysicianStaffId);
        form2.setFieldsValue({
          selectSecondPhysicianValue: secondPhysicianStaffId,
        });
        if (renderDesign) {
          let transformedValues = {};
          const keys = Object.keys(values);
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const value = values[key];
            if (value?.startsWith?.(DATE_INDICATOR)) {
              const dateString = value.split(DATE_INDICATOR).pop();
              const date = moment(dateString);
              transformedValues[key] = date;
            } else transformedValues[key] = value;
          }

          form.setFieldsValue(transformedValues);

          // if (values?.customerSignature)
          //   setSignatureValue(values?.customerSignature);
        }
      });
    } else {
      dispatch(getFormByType(formCode, true))
        .then((res) => {
          if (res?.status === "success") {
            setError(false);

            // const data = res?.data;
            // const formData = data?.formVersions?.[0];
            // const { formId, formJSON, formVersionId, versionNo } = formData;

            const data = res?.data;
            const {
              designJSON,
              latestVersionId,
              formValueId,
              formType,
              formId,
            } = data ?? {};
            let design = {};
            try {
              design = JSON.parse(designJSON || "{}");
            } catch (e) {
              console.log("parse error");
            }

            setRecord({
              design,
              formVersionId: latestVersionId,
              formValueId,
              formType,
              formId,
              requiredSecondSignature,
            });
          } else {
            setError(true);
          }
        })
        .catch(() => {
          setError(true);
        });
    }
  };

  useEffect(() => {
    if (visible) {
      loadData();
      if (isEdit) setAgreeAllConsents(false);
      else setAgreeAllConsents(true);
    } else {
      form.resetFields();
      setRecord();
      clearSignatureValue();
      clearSignatureValue1?.();
      setAgreeAllConsents(false);
      setRequiredSecondSignature(false);
      // setSelectSecondPhysicianValue(null);
      form2.resetFields();
    }
  }, [visible, isSubmitted, isEdit, partialEdit]);

  useEffect(() => {
    const customerSignature = record?.values?.customerSignature;
    const nurseSignature = record?.values?.nurseSignature;
    const np1Signature = record?.values?.np1Signature;
    const np2Signature = record?.values?.np2Signature;

    if (
      (customerSignature || np1Signature) &&
      visible &&
      (!isEdit || partialEdit)
    ) {
      clearSignatureValue();
      setSignatureValue(customerSignature || np1Signature);
    } else {
      clearSignatureValue();
    }

    if (
      (nurseSignature || np2Signature) &&
      visible &&
      (!isEdit || partialEdit)
    ) {
      clearSignatureValue1();
      setSignatureValue1(nurseSignature || np2Signature);
    } else {
      clearSignatureValue1();
    }
  }, [visible, record]);

  /** handleSave just updates the formValuesJson */
  const getSubmitableData = (data) => {
    const transformedValues = {};
    const keys = Object.keys(data);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const value = data[key];
      if (moment.isMoment(value))
        transformedValues[key] = DATE_INDICATOR + value?.toString();
      else transformedValues[key] = value;
    }
    return transformedValues;
  };

  const handleSubmit = (values) => {
    const { formId, formVersionId, formValueId } = record;

    const transformedValues = getSubmitableData(values);
    if (
      getSignatureValue &&
      record?.design?.find((item) => item?.key === "GuestSignature")
    ) {
      const signature = getSignatureValue();
      if (signature === "") {
        openNotificationWithIcon(
          "error",
          "Error!",
          "Please sign the consent form"
        );
        return;
      }
      values.customerSignature = signature;
      transformedValues.customerSignature = signature;
    }
    if (
      getSignatureValue1 &&
      record?.design?.find((item) => item?.key === "NurseSignature")
    ) {
      const signature1 = getSignatureValue1();
      if (signature1 === "") {
        openNotificationWithIcon(
          "error",
          "Error!",
          "Please provide nurse signature"
        );
        return;
      }
      values.nurseSignature = signature1;
      transformedValues.nurseSignature = getSignatureValue1();
    }

    dispatch(
      postForm(
        formId,
        formVersionId,
        transformedValues,
        customerId,
        type === FormType.historyOrPhysical ? "physicalHistory" : "consent"
      )
    ).then(() => {
      if (onSubmit) onSubmit();
    });
  };

  const handleGoodFaithSave = async (inputFormValues) => {
    const transformedValues = getSubmitableData(inputFormValues);
    const { formId, formVersionId, formValueId, values } = record;

    transformedValues.np1Signature = values?.np1Signature;
    transformedValues.np2Signature = values?.np2Signature;

    transformedValues.notes = notesRef.current;

    let selectSecondPhysicianValue = undefined;
    if (requiredSecondSignature) {
      selectSecondPhysicianValue = await form2
        .validateFields()
        .then((values) => {
          return values?.selectSecondPhysicianValue;
        })
        .catch(() => {
          form2.scrollToField("selectSecondPhysicianValue", {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
          console.log("error");
        });
    }

    if (!selectSecondPhysicianValue && requiredSecondSignature) {
      return;
    }

    dispatch(
      postGoodFaithForm(
        formId,
        formVersionId,
        transformedValues,
        customerId,
        notesRef.current,
        formValueId,
        requiredSecondSignature,
        false,
        isNP1GoodFaithSignedOff ? record?.firstPhysicianStaffId : undefined,
        selectSecondPhysicianValue
      )
    ).then(() => {
      onSubmit?.();
    });
  };

  const handleGoodFaithSubmit = async (values) => {
    const transformedValues = getSubmitableData(values);
    const { formId, formVersionId, formValueId } = record;

    let selectSecondPhysicianValue = undefined;
    if (requiredSecondSignature) {
      selectSecondPhysicianValue = await form2
        .validateFields()
        .then((values) => {
          return values?.selectSecondPhysicianValue;
        })
        .catch(() => {
          console.log("nurse field rerror");
          form2.scrollToField("selectSecondPhysicianValue", {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
          console.log("error");
        });
    }

    console.log("selectSecondPhysicianValue", selectSecondPhysicianValue);

    if (!selectSecondPhysicianValue && requiredSecondSignature) {
      return;
    }

    let shouldMarkComplete = false;
    if (
      getSignatureValue &&
      getSignatureValue1 &&
      record?.design?.find((item) => item?.key === "GoodFaithSignature")
    ) {
      const signature = getSignatureValue();
      const signature1 = getSignatureValue1();

      if (signature !== "") {
        transformedValues.np1Signature = signature;
        if (requiredSecondSignature == false) {
          shouldMarkComplete = true;
        }
      }

      if (
        signature1 !== "" &&
        requiredSecondSignature &&
        isSecondPhysicianLoggedIn
      ) {
        transformedValues.np2Signature = signature1;
      }

      if (!signature && !signature1) {
        openNotificationWithIcon(
          "error",
          "Error!",
          "NP1 Signatures required to submit"
        );
        return;
      }

      if (isNP1GoodFaithSignedOff && !signature1) {
        openNotificationWithIcon(
          "error",
          "Error!",
          "NP2 Signatures required to submit"
        );
        return;
      }

      if (isNP2GoodFaithSignedOff && !signature) {
        openNotificationWithIcon(
          "error",
          "Error!",
          "Please sign the Good Faith Exam form with NP1"
        );
        return;
      }

      if (!!signature && !!signature1) {
        shouldMarkComplete = true;
      }

      transformedValues.notes = notesRef.current;
      console.log("transformedValues", transformedValues);

      dispatch(
        postGoodFaithForm(
          formId,
          formVersionId,
          transformedValues,
          customerId,
          shouldMarkComplete ? notesRef.current ?? [] : undefined,
          formValueId,
          requiredSecondSignature,
          shouldMarkComplete,
          isNP1GoodFaithSignedOff
            ? record?.firstPhysicianStaffId
            : currentUser?.staffId,
          selectSecondPhysicianValue
        )
      ).then(() => {
        onSubmit?.();
      });
    }
  };

  return (
    <Modal
      key={title}
      destroyOnClose={false}
      className={`intakeForm ${className}`}
      title={title}
      visible={visible}
      width={812}
      onCancel={onClose}
      footer={
        <div
          className="flex "
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            borderTop: "1px solid #eff1f4",
            paddingTop: 4,
          }}
        >
          {title === "Consents" && (
            <Checkbox
              className="checkboxRegular flatFormItemCheckbox"
              checked={agreeAllConsensts}
              disabled={!isEdit}
              style={{ margin: 0, alignItems: "center" }}
              onChange={(e) => {
                setAgreeAllConsents((state) => {
                  return !state;
                });
                if (e.target.checked === false) {
                  clearSignatureValue();
                }
              }}
            >
              I agree to all consents
            </Checkbox>
          )}

          {(isEdit || partialEdit) && !error && record?.design && (
            <>
              {record?.formType === FormType.goodFaithExam ? (
                <div
                  style={{
                    display: "flex",
                    gap: 16,
                  }}
                >
                  <Button
                    onClick={async (e) => {
                      e.preventDefault();
                      form
                        .validateFields()
                        .then((values) => {
                          handleGoodFaithSave(values, false);
                        })
                        .catch((e) => {
                          console.log(e);
                          form.scrollToField(e?.[0]?.name ?? "", {
                            scrollMode: "if-needed",
                            block: "center",
                            behavior: "smooth",
                          });
                        });
                    }}
                    rounded={true}
                    style={{ paddingLeft: 60, paddingRight: 60 }}
                    // disabled={shoudDisableGFE}
                  >
                    Save Good Faith
                  </Button>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      form
                        .validateFields()
                        .then((values) => {
                          handleGoodFaithSubmit(values);
                        })
                        .catch((e) => {
                          console.log(e);
                          form.scrollToField(e?.[0]?.name ?? "", {
                            scrollMode: "if-needed",
                            block: "center",
                            behavior: "smooth",
                          });
                        });
                    }}
                    rounded={true}
                    // type="submit"
                    style={{ paddingLeft: 60, paddingRight: 60 }}
                    // disabled={shoudDisableGFE}
                  >
                    Submit
                  </Button>
                </div>
              ) : (
                <Button
                  onClick={() => submitBtnRef?.current?.click()}
                  rounded={true}
                  type="submit"
                  style={{ paddingLeft: 60, paddingRight: 60 }}
                >
                  Save
                </Button>
              )}
            </>
          )}
        </div>
      }
    >
      <Form
        disabled={(!isEdit && !partialEdit) || shoudDisableGFE}
        form={form}
        layout="vertical"
        className="intakeFormWrapper consentForm"
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          form.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
        onFinish={handleSubmit}
      >
        {error && (
          <div style={{ marginBottom: 16 }}>
            <Text>
              Error has occured while loading form.
              <AntdButton
                type="text"
                onClick={() => {
                  loadData();
                }}
                disabled={false}
              >
                Retry
              </AntdButton>
            </Text>
          </div>
        )}
        {record?.design ? (
          <FormParsedUI
            data={record?.design}
            form={form}
            signature={signature}
            getSignatureValue={getSignatureValue}
            clearSignatureValue={clearSignatureValue}
            setSignatureValue={setSignatureValue}
            isSignatureDisabled={
              (!isEdit && !partialEdit) || isNP1GoodFaithSignedOff
            }
            signature1={signature1}
            getSignatureValue1={getSignatureValue1}
            clearSignatureValue1={clearSignatureValue1}
            setSignatureValue1={setSignatureValue1}
            isSignature1Disabled={
              (!isEdit && !partialEdit) ||
              !isSecondPhysicianLoggedIn ||
              !requiredSecondSignature ||
              isNP2GoodFaithSignedOff
            }
          >
            {record?.formType === FormType.goodFaithExam && (
              <Notes
                customerId={customerId}
                formValueId={record?.formValueId}
                notesRef={notesRef}
                disabled={isNotesDisabled}
                hasStarredFunctionality={false}
              />
            )}
          </FormParsedUI>
        ) : (
          <Skeleton />
        )}
        <FormHiddenSubmitButton submitRef={submitBtnRef} />
      </Form>

      {title === "Good Faith Exam" && (
        <Checkbox
          className="checkboxRegular flatFormItemCheckbox"
          checked={requiredSecondSignature}
          disabled={isSubmitted}
          style={{ margin: 0, alignItems: "center" }}
          onChange={(e) => {
            setRequiredSecondSignature((state) => {
              return !state;
            });
          }}
        >
          Require Second Signature
        </Checkbox>
      )}
      {type === FormType.goodFaithExam && requiredSecondSignature && (
        <Form
          form={form2}
          layout="vertical"
          onFinish={(values) => {
            AntdModal.confirm({
              title: "Change NP2",
              content: <>Are you sure that you want to change the NP2?</>,
              centered: true,
              maskClosable: true,
              cancelText: "Cancel",
              onCancel: (close) => {
                close();
              },
              onOk: (close) => {
                dispatch(
                  updateGoodFaithPhysician(
                    customerId,
                    record?.formValueId,
                    values.selectSecondPhysicianValue,
                    2,
                    record?.formId,
                    record?.latestVersionId
                  )
                )
                  .then((res) => {
                    if (res?.status !== "success") {
                    } else {
                      setRecord((state) => ({
                        ...state,
                        secondPhysicianStaffId:
                          values.selectSecondPhysicianValue,
                      }));
                      refreshData?.();
                      // getData();
                    }
                  })
                  .catch(() => {})
                  .finally(() => {
                    close();
                  });
              },
            });
          }}
        >
          <div style={{ display: "flex", alignItems: "flex-end", gap: 10 }}>
            <div>
              <p className={styles.normalFont}>NP/Physician #2</p>
              <Form.Item
                style={{ marginBottom: 0 }}
                name="selectSecondPhysicianValue"
                rules={[
                  {
                    required: requiredSecondSignature,
                    message: "Second NP is required",
                  },
                ]}
              >
                <Select
                  disabled={isSubmitted}
                  onChange={() => {
                    clearSignatureValue1();
                  }}
                  // value={selectSecondPhysicianValue}
                  // onChange={(value) => {
                  //   setSelectSecondPhysicianValue(value);
                  // }}
                  className={["home_select", styles.selector]}
                  // options={allStaffs.map(staff => ({ label: staff.name, value: staff.staffId }))}
                  placeholder={"Select"}
                  showSearch
                  style={{
                    width: 200,
                    margin: 0,
                  }}
                  optionFilterProp="label"
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={filteredStaff}
                />
              </Form.Item>
            </div>
            {/* {isNP1GoodFaithSignedOff && !isSubmitted && (
              <Button htmlType={"submit"} rounded>
                Update Nurse
              </Button>
            )} */}
          </div>
        </Form>
      )}
    </Modal>
  );
}

IntakeFormModal.prototype = {};
