import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createIntakeFormNote,
  deleteNote,
  getIntakeFormNotes,
  updateCustomerNotes,
} from "services/guests.services";

import {
  DeleteOutlined,
  EditOutlined,
  StarFilled,
  StarOutlined,
} from "@ant-design/icons";
import {
  Col,
  Row,
  Space,
  Button as AntdButton,
  Table,
  Modal,
  Form,
} from "antd";
import {
  FormCheckbox,
  FormInputArea,
} from "components/FormItems/FlatFormItems";
import moment from "moment";
import { setLoading } from "redux/actions/app.actions";

export default function Notes({
  customerId,
  formValueId,
  notesRef,
  disabled,
  hasStarredFunctionality = true,
}) {
  const dispatch = useDispatch();
  const [notes, setNotes] = React.useState({
    data: [],
    isLoading: false,
  });
  const [showNoteForm, setShowNoteForm] = React.useState(false);
  const [selectedNote, setSelectedNote] = React.useState(null);

  const [form] = Form.useForm();
  const loggedUser = useSelector((state) => state.user.currentUser);
  console.log("loggedUser", loggedUser);

  React.useEffect(() => {
    if (!!customerId && !!formValueId) loadData(customerId, formValueId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, formValueId]);

  function loadData(customerId, formValueId) {
    setNotes((state) => ({
      ...state,
      isLoading: true,
    }));
    dispatch(
      getIntakeFormNotes(customerId, formValueId, (response) => {
        setNotes((state) => ({
          ...state,
          isLoading: false,
          data: response,
        }));
      })
    );
  }

  useEffect(() => {
    notesRef.current = notes.data ?? [];
  }, [notes.data]);

  const columns = [
    {
      title: "Notes",
      dataIndex: "noteDescription",
      key: "noteDescription",
      render: (text, record) => {
        const utc = moment.utc(record.createdOn);
        const cst = utc.tz("America/Chicago");

        console.log("normal", record?.createdOn);
        console.log("cst", cst?.format());

        return (
          <td style={{ display: "grid" }}>
            <div
              style={{
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                gap: 4,
              }}
            >
              {hasStarredFunctionality && record?.isStarred && (
                <StarFilled
                  style={{
                    color: "#F9C237",
                  }}
                />
              )}
              <span style={{ fontWeight: "inherit" }}>
                {record.noteDescription}
              </span>
            </div>

            <span style={{ fontSize: "12px", color: "gray" }}>
              {cst.format("YYYY-MM-DD HH:mm:ss")} (CST)
            </span>
          </td>
        );
      },
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      width: 150,
      ellipisis: true,
      textWrap: "word-break",
      render: (text, record) => {
        return (
          <p
            style={{
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              // whiteSpace: 'nowrap',
            }}
          >
            {text}
          </p>
        );
      },
    },
    // {
    //   title: "Created On",
    //   dataIndex: "createdOn",
    //   key: "createdOn",
    //   width: 150,
    //   ellipsis: true,
    //   render: (text, record) => {
    //     return <>{moment(record.createdOn).format("YYYY-MM-DD HH:mm:ss")}</>;
    //   },
    // },

    {
      title: "",
      key: "action",
      align: "right",
      width: 80,
      render: (text, record) => (
        // <CheckCircleOutlined style={{ fontSize: 20, color: "#52c41a" }} />
        <Space size={"small"}>
          {loggedUser?.roleName === "Admin" ||
          loggedUser?.email === record?.createdBy ? (
            <EditOutlined
              style={{
                pointerEvents: disabled ? "none" : "auto",
                cursor: disabled ? "default" : "pointer",
              }}
              onClick={() => {
                if (disabled) return;
                // alert("edit");
                form.setFieldsValue({
                  noteDescription: record.noteDescription,
                  isStarred: record.isStarred,
                });
                // setStarredChecked(true);
                setSelectedNote(record);
                setShowNoteForm(true);
                setTimeout(() => {
                  form.scrollToField("noteDescription", {
                    scrollMode: "if-needed",
                    block: "center",
                    behavior: "smooth",
                  });
                }, 200);
              }}
            />
          ) : null}

          {(loggedUser?.roleName === "Admin" ||
            loggedUser?.email === record?.createdBy) && (
            <DeleteOutlined
              style={{
                pointerEvents: disabled ? "none" : "auto",
                cursor: disabled ? "default" : "pointer",
              }}
              onClick={() => {
                if (disabled) return;

                Modal.confirm({
                  title: "Confirm",
                  okText: "Ok",
                  cancelText: "Cancel",
                  content: "Are you sure you want to delete this note?",
                  onOk: async () => {
                    if (!formValueId) {
                      setNotes((state) => ({
                        ...state,
                        data: state.data.filter(
                          (note) => note.noteId !== record.noteId
                        ),
                      }));
                    } else {
                      await dispatch(
                        deleteNote(record.customerId, record.noteId, () => {
                          loadData(customerId, formValueId);
                        })
                      );
                    }
                  },
                });
              }}
            />
          )}
        </Space>
      ),
    },
  ];

  return (
    <Row>
      <Col xs={24}>
        <Space
          size={"small"}
          direction="horizontal"
          style={{ marginLeft: "82%" }}
        >
          <AntdButton
            disabled={!customerId || disabled}
            htmlType="button"
            rounded
            style={{
              marginBottom: 16,
              display: "flex",
              alignItems: "center",
            }}
            type="text"
            onClick={() => {
              form.resetFields();
              setTimeout(() => {
                form.scrollToField("noteDescription", {
                  scrollMode: "if-needed",
                  block: "center",
                  behavior: "smooth",
                });
              }, 250);
              setShowNoteForm(true);
            }}
          >
            Add Note
          </AntdButton>
        </Space>

        <Table
          tableLayout="auto"
          locale={{ emptyText: "No Notes" }}
          dataSource={notes?.data ?? []}
          loading={notes.isLoading}
          columns={columns}
          // columns={columns.filter((column) => {
          //   if (!hiddenColumns.includes(column.title)) return true;
          //   // return false;
          // })}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: [15, 25, 50, 100, 250],
          }}
          scroll={{
            // y: "calc(100vh - 80px - 96px - 60px - 100px)",
            x: true,
          }}
        />

        {showNoteForm && (
          <Form
            disabled={disabled}
            preserve={false}
            // initialValues={{
            //   noteDescription: selectedNote.noteDescription,
            // }}
            form={form}
            layout="vertical"
            onFinishFailed={(errors) => {
              console.log("errors", errors);
            }}
            onFinish={async (values) => {
              if (!formValueId) {
                if (selectedNote) {
                  setNotes((state) => ({
                    ...state,
                    data: state.data.map((note) => ({
                      ...note,
                      noteDescription: values.noteDescription,
                      isStarred: values.isStarred ? true : false,
                    })),
                  }));
                } else {
                  setNotes((state) => ({
                    ...state,
                    data: [
                      ...state.data,
                      {
                        noteDescription: values.noteDescription,
                        isStarred: values.isStarred ? true : false,
                        createdBy:
                          loggedUser.firstName + " " + loggedUser.lastName,
                        createdOn: new Date(),
                      },
                    ],
                  }));
                }
                setSelectedNote(null);
                setShowNoteForm(false);
                return;
              }

              dispatch(setLoading(true));
              if (selectedNote) {
                await dispatch(
                  updateCustomerNotes(
                    {
                      noteDescription: values.noteDescription,
                      isStarred: values.isStarred ? true : false,
                    },
                    customerId,
                    selectedNote.noteId,
                    () => {
                      loadData(customerId, formValueId);
                    }
                  )
                );
              } else {
                dispatch(
                  createIntakeFormNote(
                    customerId,
                    formValueId,
                    [values],
                    (data) => {
                      loadData(customerId, formValueId);
                    }
                  )
                );
              }
              setSelectedNote(null);
              setShowNoteForm(false);
            }}
          >
            <FormInputArea
              disabled={false}
              label={selectedNote ? "Edit Note" : "Add Note"}
              name={"noteDescription"}
              formItemStyles={{ width: "100%" }}
              required={true}
              // initialValue={{
              //   noteDescription: selectedNote.noteDescription,
              // }}
            />

            <FormCheckbox
              disabled={false}
              hidden={!hasStarredFunctionality}
              name="isStarred"
              className="checkboxRegular"
              formItemStyles={{ marginBottom: 14 }}
              onChange={async (e) => {
                // if (e.target.checked) {
                // setStarredChecked(e.target.checked);
              }}
              // initialValue={{
              //   isStarred: starredChecked ? true : false,
              // }}
              // initialValue={starredChecked ? true : false}
            >
              Starred (
              <StarOutlined />)
            </FormCheckbox>
            <Space
              size={"small"}
              direction="horizontal"
              style={{ marginLeft: "72%" }}
            >
              <AntdButton
                onClick={() => {
                  form.submit();
                }}
                // htmlType="submit"
                disabled={disabled}
                rounded
                style={{
                  marginBottom: 16,
                  display: "flex",
                  alignItems: "center",
                }}
                type="text"
              >
                {selectedNote ? "Update" : "Add"} Note
              </AntdButton>
              <AntdButton
                disabled={disabled}
                htmlType="button"
                rounded
                style={{
                  marginBottom: 16,
                  display: "flex",
                  alignItems: "center",
                }}
                type="text"
                onClick={() => {
                  form.resetFields();
                  setShowNoteForm(false);
                }}
              >
                Cancel
              </AntdButton>
            </Space>
          </Form>
        )}
      </Col>
    </Row>
  );
}
