import React from "react";
import { List } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getGoodFaiths } from "services/guests.services";

import { FormType } from "utils/enums";
import IntakeFormModal from "containers/IntakeForms/components/IntakeFormModal";
import GoodFaithCard from "./components/GoodFaithCard";

const DEFAULT_PAGE_SIZE = 10;

export default function GoodFaithsList({
  isSmallDevice,
  searchText,
  page,
  setPage,
  sorting,
}) {
  const dispatch = useDispatch();
  const abortConRef = React.useRef();

  const wholeStaff = useSelector((state) => state.user?.wholeStaff);
  const allRoles = useSelector((state) => state.roles.allRoles);
  const filteredStaff = React.useMemo(() => {
    return wholeStaff
      ?.filter?.((staff) => {
        const role = allRoles?.find?.((role) => role.roleId === staff?.roleId);
        if (
          role?.permissions?.find?.(
            (permission) => permission.permissionName === "GFE Signature"
          )
        )
          return staff.isActive;
        return false;
      })
      ?.map((staff) => ({
        value: staff.staffId,
        label: staff.firstName + " " + staff.lastName,
      }));
  }, [wholeStaff, allRoles]);

  const [forms, setForms] = React.useState({
    data: [],
    isLoading: true,
    totalRecords: 0,
  });
  const [showGoodFaithExam, setshowGoodFaithExam] = React.useState({
    visible: false,
    isEdit: false,
    form: null,
  });

  const getData = (page, text = "") => {
    setForms((prev) => ({ ...prev, isLoading: true }));
    if (abortConRef.current) abortConRef.current.abort();
    abortConRef.current = new AbortController();
    dispatch(
      getGoodFaiths(
        (page - 1) * DEFAULT_PAGE_SIZE,
        DEFAULT_PAGE_SIZE,
        text,
        sorting,
        true,
        abortConRef?.current?.signal
      )
    )
      .then((res) => {
        if (res?.status === "success") {
          setForms((state) => ({
            ...state,
            data: res?.data,
            totalRecords: res?.totalRecords,
          }));
        }
      })
      .finally(() => {
        setForms((state) => ({
          ...state,
          isLoading: false,
        }));
      });
  };

  function renderItem(form, index) {
    return (
      <GoodFaithCard
        form={form}
        filteredStaff={filteredStaff}
        key={form.customerId} // TODO: Guest > Form
        getData={() => getData(page, searchText)}
        isSmallDevice={isSmallDevice}
        onClick={() => {
          console.log("form ", form);
          setshowGoodFaithExam({
            form,
            visible: true,
            isEdit: !form?.isGoodFaithFormSubmitted,
          });
        }}
      />
    );
  }

  React.useEffect(() => {
    getData(page, searchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchText, sorting]);

  return (
    <div
      style={{
        overflowY: "auto",
        height: isSmallDevice
          ? "calc(100vh - 135px - 16px - 80px)"
          : "calc(100vh - 80px - 16px - 80px)",
        background: "#e5e8ee",
        display: "flex",
        flexDirection: "column",
        // alignItems: "center",
        paddingBottom: 30,
      }}
    >
      <List
        itemLayout="vertical"
        size="large"
        pagination={{
          onChange: (page) => {
            console.log(page);
            setPage(page);
          },
          pageSize: DEFAULT_PAGE_SIZE,
          total: forms?.totalRecords ?? 0,
        }}
        dataSource={forms?.data ?? []}
        // loading={guests?.isLoading}
        renderItem={renderItem}
      />
      {showGoodFaithExam.visible && (
        <IntakeFormModal
          visible={showGoodFaithExam.visible}
          isEdit={showGoodFaithExam.isEdit}
          title="Good Faith Exam"
          type={FormType.goodFaithExam}
          onSubmit={() => {
            setshowGoodFaithExam({
              form: null,
              visible: false,
              isEdit: false,
            });
            getData(page, searchText);
          }}
          onClose={() =>
            setshowGoodFaithExam({
              form: null,
              visible: false,
              isEdit: false,
            })
          }
          refreshData={() => getData(page, searchText)}
          isSubmitted={showGoodFaithExam?.form?.isGoodFaithFormSubmitted} // TODO: Guest > Form
          formId={showGoodFaithExam?.form?.formValueId}
          customerId={showGoodFaithExam?.form?.customerId} // TODO Guest > Form
          formCode={4}
          formValueId={showGoodFaithExam?.form?.formValueId}
          // orderId={orderId}
          className={`goodfaithexam-${showGoodFaithExam?.form?.customerId}`}
        />
      )}
    </div>
  );
}
