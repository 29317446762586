import {
  DatePicker,
  Empty,
  Form,
  Menu,
  Select,
  Space,
  Grid,
  Segmented,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentStaff } from "../../redux/actions/user.actions";
import { setLoading } from "../../redux/actions/app.actions";

import { getAllStaff } from "../../services/staffs.services";
import moment from "moment-timezone";
import { findWindows } from "windows-iana";
import EventDrawer from "components/EventDrawer/EventDrawer";
import {
  assignNursePractitioner,
  getChartsByStatus,
} from "services/charts.services";
import { openChartURL } from "utils/common";

import styles from "./Charts.module.scss";

const { Option } = Select;
const { useBreakpoint } = Grid;

const Charts = ({ date, sorting }) => {
  const dispatch = useDispatch();
  const screens = useBreakpoint();

  const [modalVisible, setModalVisible] = useState(false);

  const allStaffs = useSelector((state) => state.user.wholeStaff).filter(
    (staff) => {
      if (
        staff.roleName === "Nurse Practitioner" ||
        staff.roleName === "Physician" ||
        staff.roleName === "Nurse Lead"
      )
        return true;
      return false;
    }
  );
  const allCharts = useSelector((state) => state.charts.allCharts);
  const currentStaff = useSelector((state) => state.user.currentUser);
  const currentOrder = useSelector((state) => state.orders.currentOrder);

  const getAllPractitioners = async () => {
    dispatch(setLoading(true));
    await dispatch(getAllStaff(null, []));
    dispatch(setLoading(false));
  };

  useEffect(() => {
    if (currentStaff?.staffId) getData();
  }, [date, currentStaff]);

  const getData = async () => {
    const windowsTimezone = findWindows(moment.tz.guess());
    const roleName = currentStaff.roleName?.trim?.() || "";
    dispatch(setLoading(true));
    await dispatch(
      getChartsByStatus("Pending", {
        serviceDate: moment.utc(date).local().format("YYYY-MM-DD"),
        staffId:
          currentStaff &&
          !(
            roleName === "Admin" ||
            roleName === "Nurse Practitioner" ||
            roleName === "Nurse Lead"
          )
            ? currentStaff.staffId
            : null,
        timezone: windowsTimezone?.[0],
      })
    );
    // await dispatch(getAllStaff(currentStaff?.facilityId, ['Nurse Practitioner']))
    dispatch(setLoading(false));
  };

  useEffect(() => {
    if (currentStaff) {
      // getData();
      getAllPractitioners();
    }
  }, [currentStaff]); //eslint-disable-line

  const isSmallDevice = !screens.md;

  const renderAppointments = React.useMemo(() => {
    const currentStaffRoleName = currentStaff?.roleName?.trim?.() || "";
    if (allCharts.length) {
      let data = [...allCharts];
      if (sorting === "pending") {
        data = data.filter((chart) => chart.staffName && !chart.staffSignature);
      } else if (sorting === "signedOff") {
        data = data.filter((chart) => chart.staffName && chart.staffSignature);
      } else if (sorting === "newestToOldest") {
        data = data.sort(
          (a, b) => moment(b.serviceDate) - moment(a.serviceDate)
        );
      } else if (sorting === "oldestToNewest") {
        data = data.sort(
          (a, b) => moment(a.serviceDate) - moment(b.serviceDate)
        );
      } else if (sorting === "inClinic") {
        data = data.filter((chart) => chart.operationType.name === "Clinic");
      } else if (sorting === "mobile") {
        data = data.filter((chart) => chart.operationType.name === "Mobile");
      } else if (sorting === "all") {
        data = [...allCharts];
      }

      return data.map((chart, id) => {
        const hours = parseInt(
          moment
            .utc()
            .local()
            .diff(moment.utc(chart.staffSignedOffDate).local(), "hours")
        );
        return (
          <div
            key={chart.chartId}
            className={styles.card}
            style={{
              cursor: "pointer",
            }}
          >
            <div
              className={styles.section1}
              onClick={() => {
                openChartURL(chart.orderId, chart.orderLineItemId, {
                  isLegacy: chart.isLegacy,
                  productName: chart.legacyChartName,
                });
              }}
            >
              <p className={styles.largeFont}>{chart.guestName}</p>
              <p className={styles.normalFont}>{chart.chartName}</p>
            </div>
            <div
              className={styles.section2}
              onClick={() => {
                openChartURL(chart.orderId, chart.orderLineItemId, {
                  isLegacy: chart.isLegacy,
                  productName: chart.legacyChartName,
                });
              }}
            >
              <div>
                <p className={styles.normalFont}>Clinician</p>
                <p className={styles.largeFont}>{chart.staffName}</p>
              </div>
              <div>
                <p className={styles.boldFont}>Status</p>
                <div className={styles.signaturesTextContainer}>
                  <p
                    className={styles.boldFont}
                    style={{
                      color: "#27CA2D",
                    }}
                  >
                    {chart.staffName
                      ? chart.staffSignature
                        ? "Signed off"
                        : "Pending"
                      : "Unassigned"}
                  </p>
                  <p className={styles.signatureText}>
                    {chart.staffSignedOffDate &&
                      moment
                        .utc(chart.staffSignedOffDate)
                        .local()
                        .format("ddd Do MMM YYYY hh:mm a")}
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.section3}>
              <div>
                <p className={styles.normalFont}>NP/Physician</p>
                <Select
                  className={["home_select", styles.selector]}
                  // options={allStaffs.map(staff => ({ label: staff.name, value: staff.staffId }))}
                  placeholder={"Select"}
                  value={chart.staffPhysicianId ? chart.staffPhysicianId : null}
                  disabled={
                    !(
                      currentStaffRoleName === "Nurse Practitioner" ||
                      currentStaffRoleName === "Nurse Lead" ||
                      currentStaffRoleName === "Admin"
                    ) ||
                    // chart.staffPhysicianId ||
                    !chart.staffSignature
                  }
                  onChange={async (val) => {
                    try {
                      dispatch(setLoading(true));
                      await assignNursePractitioner(val, chart.chartId);
                      getData();
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                >
                  {allStaffs.map((staff) => (
                    <Option key={staff.staffId} value={staff.staffId}>
                      {staff.name}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className={styles.flexJustifyBetweenAlignEnd}>
                <div>
                  <p className={styles.boldFont}>Status</p>
                  <div className={styles.signaturesTextContainer}>
                    <p
                      className={styles.boldFont}
                      style={{
                        color: "#8E94AA",
                      }}
                    >
                      {chart.staffPhysicianId
                        ? chart.physicianSignature
                          ? "Signed off"
                          : "Pending"
                        : "Unassigned"}
                    </p>
                    <p className={styles.signatureText}>
                      {chart.physicianSignedOffDate &&
                        moment
                          .utc(chart.physicianSignedOffDate)
                          .local()
                          .format("ddd Do MMM YYYY hh:mm a")}
                    </p>
                  </div>
                </div>
                <div>
                  {chart.staffSignature &&
                    chart.staffSignedOffDate &&
                    !chart.physicianSignature && (
                      <div
                        className={styles.badge}
                        style={{
                          background:
                            hours < 24
                              ? "#27CA2D"
                              : hours
                              ? "#E4C865"
                              : "#FF0000",
                        }}
                      >
                        {hours} hours
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else {
      return (
        <div style={{ height: 300, display: "flex", alignItems: "center" }}>
          <Empty description="No Charts" />
        </div>
      );
    }
  }, [allCharts, currentStaff, allStaffs, sorting]);

  return (
    <div>
      <EventDrawer
        order={currentOrder}
        setShowEventDrawer={setModalVisible}
        visible={modalVisible}
        onClose={() => {
          setModalVisible(false);
          dispatch(setCurrentStaff(null));
        }}
        getData={getData}
      />

      <div
        style={{
          overflowY: "auto",
          height: isSmallDevice
            ? "calc(100vh - 135px - 16px - 80px)"
            : "calc(100vh - 80px - 16px - 80px)",
          background: "#e5e8ee",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: 30,
        }}
      >
        {renderAppointments}
      </div>
    </div>
  );
};

export default Charts;
